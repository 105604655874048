import getMessage from "../../utils/getMessage";
import chatbubble from '../../../assets/images/chatbubble.svg';
import { FormattedMessage, FormattedPlural } from "react-intl";


const HearingInformationCommentCount = ({
  hearing, 
  justNumber = true
}) => {
  return (
    <div style={{display: 'grid', gridTemplateColumns: 'min-content 1fr', alignItems: 'center', gap: '1ch'}}>
      <img
        className="hearing-image"
        src={chatbubble}
        style={{ color: '#000000' }}
        alt={getMessage('commentCount')}
      />
      <span>
        {
          justNumber
            ? <>{hearing.n_comments}</>
            : 
            <FormattedPlural 
              value={hearing.sections[0].n_comments}
              one={<FormattedMessage id="sectionTotalComment" values={{ n: hearing.sections[0].n_comments }} />}
              other={<FormattedMessage id="sectionTotalComments" values={{ n: hearing.sections[0].n_comments }} />}
            />
        }
      </span>
    </div>
  )
}

export default HearingInformationCommentCount;