import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import LoadSpinner from '../../../../LoadSpinner';
import Icon from '../../../../../utils/Icon';
import NewReleasesIcon from '@mui/icons-material/NewReleases';

/**
 * Functional component to display "Show more reply's button"
 * Keep component simple and effective and contained within comment component hierarchy.
 */
const ShowMore = ({
                    numberOfComments,
                    onClickShowMore,
                    isLoadingSubComment,
                    isInNewCommentChain,
                  }) => {
  /**
   * Event hadler for when hyperlink is clicked.
   * Override and prevent default action so it doesnt act like a hyper link.
   */
  const handleShowMore = event => {
    event.preventDefault();
    onClickShowMore();
  };

  return (
    <div className="hearing-comment__show-more">
      {isLoadingSubComment ? (
        <LoadSpinner style={{ padding: '12px' }} />
      ) : (
        <span className="hearing-comment__show-more__wrapper">
          <button type="button" className="hearing-comment-action-button" onClick={handleShowMore}>
            <span>
              <Icon name="chevron-down" aria-hidden="true" />
              <FormattedMessage id="showMoreReplies" />
            </span>
            <span className="hearing-comment__show-more__count">{`(${numberOfComments})`}</span>
          </button>
          <NewReleasesIcon sx={{ color: '#ffd54f', width:'24.999', height:'24.999', marginLeft: '5px', display: !isInNewCommentChain && 'none' }} />
        </span>
      )}
    </div>
  );
};

ShowMore.propTypes = {
  numberOfComments: PropTypes.number.isRequired,
  onClickShowMore: PropTypes.func.isRequired,
  isLoadingSubComment: PropTypes.bool,
  isInNewCommentChain: PropTypes.bool,
};

ShowMore.defaultProps = {
  isLoadingSubComment: false,
};

export default injectIntl(ShowMore);
