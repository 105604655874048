// This module uses `require` and late imports to support isomorphic rendering.
import { style, toast } from 'react-toastify';

import getMessage from './getMessage';

const successOptions = {
  autoClose: false,
  position: toast.POSITION.BOTTOM_RIGHT,
  type: toast.TYPE.SUCCESS,
};

const errorOptions = {
  autoClose: false,
  position: toast.POSITION.BOTTOM_RIGHT,
  type: toast.TYPE.ERROR
};

const infoOptions = {
  autoClose: false,
  position: toast.POSITION.BOTTOM_LEFT,
  type: toast.TYPE.INFO,
};

style({
  colorInfo: '#fff3cb', // $digiraati-primary-very-light
  colorSuccess: '#ffcf34', // $digiraati-primary-light
  colorError: '#ff73b5',
});


/** @deprecated Only used by maps? Maps are not used in Digiraati. */
export function alert(message, title = 'Digiraati') {
  if (typeof window !== 'undefined') {
    require('alertifyjs')
      .alert(
        title,
        `<div id="alert-dialog" tabindex="0" aria-labelledby="${message}">
        ${message}
      </div>`,
      )
      .set('onfocus', () => {
        document.getElementById('alert-dialog').focus();
      })
      .setting({ transition: 'slide' });
  }
}

export function localizedAlert(string) {
  return alert(getMessage(string));
}

export function notifyError(message) {
  if (typeof window !== 'undefined') {
    toast.dismiss();

    if (message) {
      toast.error(message, errorOptions); // add type: 'error' to options
    } else {
      toast.error('Jokin meni pieleen.', errorOptions); // add type: 'error' to options
    }
  }
}

export function notifySuccess(message) {
  if (typeof window !== 'undefined') {
    toast.dismiss();

    if (message) {
      toast.success(message, successOptions); // add type: 'success' to options
    } else {
      toast.success('Toiminto onnistui.', successOptions); // add type: 'success' to options
    }
  }
}

export function localizedNotifyError(string) {
  toast.dismiss();

  if (string) {
    toast.error(getMessage(string), errorOptions); // add type: 'error' to options
  } else {
    toast.error('Jokin meni pieleen.', errorOptions); // add type: 'error' to options
  }
}

export function notifyInfo(content) {
  toast.dismiss()
  if (content) {
    toast.info(content, infoOptions)
  }
}

export function localizedNotifySuccess(string) {
  toast.dismiss();

  if (string) {
    toast.success(getMessage(string), successOptions); // add type: 'success' to options
  } else {
    toast.success('Toiminto onnistui.', successOptions); // add type: 'success' to options
  }
}


