import { useEffect, useRef, useState } from "react";
import { getImageAsBase64Promise } from "../utils/hearing";
import { FormattedMessage } from "react-intl";
import { v1 as uuid } from 'uuid';
import { Alert, Checkbox, ControlLabel, FormGroup } from 'react-bootstrap';
import DigiraatiEmojiInput from "./EmojiInput";
import Icon from '../utils/Icon';
import getMessage from "../utils/getMessage";
import {Modal} from 'react-bootstrap';


const CommentFormToolsPopup = ({
  formToolsOpen, 
  setFormToolsOpen,
  setCommentSticker,
  sticker,
  images,
  setImages,
  imageTooBig,
  setImageTooBig,
  getId,
  commentTextFieldRef,
  isUserAdmin,
  user,
  hideNameForm,
  commentText,
  setCommentText,
  showAlert,
  handleCloseAlert,
  hideName,
  handleToggleHideName,
  nickname,
}) => {

  const [storedCursorPosition, setStoredCursorPosition] = useState(null)
  
  const imagesRef = useRef();
  
  useEffect(() => {
    if (storedCursorPosition) {
      const commentTextFieldElement = commentTextFieldRef.current;
      commentTextFieldElement.setSelectionRange(
        storedCursorPosition, storedCursorPosition
      );
      setStoredCursorPosition(null)
    }
  }, [commentText])
  
  const addEmojiToCommentSelectionStart = (emoji) => {
    const commentTextFieldElement = commentTextFieldRef.current;
    const cursorPosition = commentTextFieldElement.selectionStart;
    const textBefore = commentText.slice(0, cursorPosition);
    const textAfter = commentText.slice(cursorPosition);
    setStoredCursorPosition(cursorPosition+emoji.length)
    setCommentText(textBefore + emoji + textAfter);
  }

  
  const isImageTooBig = (images) => {
    // eslint-disable-line class-methods-use-this
    let isImageTooBig = false;
    Array.from(images).forEach(image => {
      if (image.size > 1000000) {
        isImageTooBig = true;
      }
    });
    setImageTooBig(isImageTooBig);
  }
  
  const handleChange = (event) => {
    const imagePromisesArray = [];
    const _images = [];
    isImageTooBig(event.target.files);
    const imagesElement = imagesRef.current;

    for (let _i = 0; _i < imagesElement.files.length; _i += 1) {
      imagePromisesArray.push(
        getImageAsBase64Promise(imagesElement.files[_i]),
      );
    }

    Promise.all(imagePromisesArray).then(arrayOfResults => {
      for (let _i = 0; _i < imagesElement.files.length; _i += 1) {
        const imageObject = { title: 'Title', caption: 'Caption' };

        imageObject.image = arrayOfResults[_i];
        _images.push(imageObject);
      }

      setImages(_images);
    });
  }

  
  /**
   * When admin user is posting a comment, we will show a closeable warning.
   */
  const renderAdminWarning = () => (
    <Alert bsStyle="warning">
      <div className="comment-form__comment-alert">
        <div className="comment-form__comment-alert__alert-icon">
          <Icon name="info-circle" size="lg" />
        </div>
        <span className="comment-form__comment-alert__alert-message">
          <FormattedMessage id="adminCommentMessage" />
        </span>
        <div className="comment-form__comment-alert__alert-close">
          <Icon name="close" onClick={handleCloseAlert} />
        </div>
      </div>
    </Alert>
  );

  /**
   * If an admin type of user is posting comment, the form is slightly different.
   * @returns {JSX<Component>}
   */
  const renderNameFormForUser = () => {
    if (hideNameForm) {
      return <></>;
    }
    const isAdminUser = isUserAdmin();
    const headingId = isAdminUser ? 'nameAndOrganization' : 'nickname';
    const organization = isUserAdmin() && user.adminOrganizations[0];

    return (
      <div className="name-and-organization">
        <label htmlFor="commentNickname" className="h4">
          <FormattedMessage id={headingId} />
        </label>
        {isAdminUser && showAlert && renderAdminWarning()}
        {isAdminUser && renderHideNameOption()}
        {isAdminUser && (
          <div className="comment-form__group-admin">
            <dl>
              <dt>{getMessage('nickname')}</dt>
              <dd>{nickname}</dd>
              <dt>{getMessage('organization')}</dt>
              <dd>{organization || ''}</dd>
            </dl>
          </div>
        )}
      </div>
    );
  };

  
  /**
   * Render the checkbox to hide user name and identitiy for admin user.
   */
  const renderHideNameOption = () => (
    <Checkbox
      checked={hideName}
      key={uuid()}
      onChange={handleToggleHideName}
    >
      <FormattedMessage id="hideName" />
    </Checkbox>
  );

  
  return (
    <Modal show={!!formToolsOpen} onHide={() => setFormToolsOpen(null)} animation={false}>
      <div className="comments-section">
        <div className="comment-form__heading-container__heading-popup comment-form">
          <div style={{overflowY: 'auto'}}>
            { (formToolsOpen === 'emoji') &&
            <DigiraatiEmojiInput
              emojiSelectionHandler={addEmojiToCommentSelectionStart}
              stickerSelectionHandler={setCommentSticker}
              selectedSticker={sticker}
            />
            }
            { (formToolsOpen === 'files') && <>
              <div className="comment-form__selected-images">
                {imageTooBig ? (
                  <div className="comment-form__image-too-big">
                    <FormattedMessage id="imageSizeError" />
                  </div>
                ) : (
                  images.map((image, key) => (
                    <img
                      style={{ marginRight: 10 }}
                      alt=""
                      src={image.image}
                      width={image.width < 100 ? image.width : 100}
                      height={image.height < 100 ? image.width : 100}
                      key={key + Math.random()} //eslint-disable-line
                    />
                  ))
                )}
              </div>
              <FormGroup className="comment-form__file">
                <ControlLabel>
                  <FormattedMessage id="add_images" />
                </ControlLabel>
                <div className="comment-form__select-file">
                  <input
                    type="file"
                    ref={imagesRef}
                    id={getId('fileInput')}
                    multiple
                    className="custom-file-input"
                    onChange={event => handleChange(event)}
                  />
                  <label className="digi-button btn btn-sm" htmlFor={getId('fileInput')}>
                    <FormattedMessage id="choose_images" />
                  </label>
                </div>
                <span style={{ fontSize: 13, marginTop: 20 }}>
                <FormattedMessage id="multipleImages" />
              </span>
              </FormGroup>
            </>}
            { (formToolsOpen === 'nameOrg') &&
              renderNameFormForUser()
            }
            </div>
        </div>
        <div style={{display: 'grid', gridTemplateColumns:'1fr'}}>
            <button type="button" 
              className="digi-button" 
              onClick={() => setFormToolsOpen(null)}
              title={getMessage('close')}
              >&times;</button>
          </div>
      </div>
    </Modal>
  )
}

export default CommentFormToolsPopup;