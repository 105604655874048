import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import LoadSpinner from '../../../../LoadSpinner';
import Icon from '../../../../../utils/Icon';
import NewReleasesIcon from '@mui/icons-material/NewReleases';

/**
 * Functional component to display "Hide replies button"
 * Keep component simple and effective and contained within comment component hierarchy.
 */
const HideComments = ({
                        numberOfComments,
                        onClickHideComments,
                        isLoadingSubComment,
                        isInNewCommentChain,
                      }) => {
  /**
   * Event hadler for when hyperlink is clicked.
   * Override and prevent default action so it doesnt act like a hyper link.
   */
  const handleHideComments = event => {
    event.preventDefault();
    onClickHideComments();
  };

  return (
    <div className="hearing-comment__hide-comments">
      {isLoadingSubComment ? (
        <LoadSpinner style={{ padding: '12px' }} />
      ) : (
        <span className="hearing-comment__hide-comments__wrapper">
          <button type="button" className="hearing-comment-action-button" onClick={handleHideComments}>
            <span>
              <Icon name="chevron-down" aria-hidden="true" />
              <FormattedMessage id="hideReplies" />
            </span>
            <span className="hearing-comment__hide-comments__count">{`(${numberOfComments})`}</span>
          </button>
          <NewReleasesIcon sx={{ color: '#ffd54f', width:'24.999', height:'24.999', marginLeft: '5px', display: !isInNewCommentChain && 'none' }} />
        </span>
      )}
    </div>
  );
};

HideComments.propTypes = {
  numberOfComments: PropTypes.number.isRequired,
  onClickHideComments: PropTypes.func.isRequired,
  isLoadingSubComment: PropTypes.bool,
  isInNewCommentChain: PropTypes.bool,
};

HideComments.defaultProps = {
  isLoadingSubComment: false,
};

export default injectIntl(HideComments);
