/* eslint-disable react/no-multi-comp */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, MenuItem, Nav, Navbar } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { FormattedMessage } from 'react-intl';
import scrolltop from 'scrolltop';
import throttle from 'lodash/throttle';

import { connect } from 'react-redux';
import { getUser } from '../../store/selectors/user';
import { toggleContrast } from '../../store/actions';

// eslint-disable-next-line import/no-unresolved
import LanguageSwitcher from './LanguageSwitcher';
import LangLink from '../LinkWithLang';
import config from '../../config';
import Icon from '../../utils/Icon';
import getMessage from '../../utils/getMessage';
import { isAdmin } from '../../utils/user';
import UserMenu from '../user/UserMenu';
import '../../views/styles.css';

const Header = ({ user, language, history, toggleContrast, location }) => {
  const [handleNavFix, setHandleNavFix] = useState()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const _handleNavFix = throttle(() => {
        const scrollY = scrolltop();
        if (scrollY > 45) {
          document.body.classList.add('nav-fixed');
        } else {
          document.body.classList.remove('nav-fixed');
        }
      }, 25);
      window.addEventListener('scroll', _handleNavFix, false);
      window.onmessage = function democracySearchMessageEvent(event) {
        if (event.origin === 'https://haku.demokratia.fi') {
          const newHeight = event.data;
          if (newHeight) {
            window.document.getElementById(
              'super-search-iframe',
            ).style.height = newHeight;
          }
        }
      };
      setHandleNavFix(_handleNavFix);
    }
  }, [])

  useEffect(() => {
    return (
      () => {
        if (handleNavFix) {
          window.removeEventListener('scroll', _handleNavFix);
        }
      }
    )
  }, [])


  // eslint-disable-next-line class-methods-use-this
  const getServiceItems = () => {
    return [
      <ul className="nav navbar-nav">
        <li className="nav-item">
          <a href="https://demokratia.fi" target="blank">
            demokratia.fi
          </a>
        </li>
        <li className="nav-item">
          <a href="https://kuntalaisaloite.fi" target="blank">
            kuntalaisaloite.fi
          </a>
        </li>
        <li className="nav-item">
          <a href="https://kansalaisaloite.fi" target="blank">
            kansalaisaloite.fi
          </a>
        </li>
        <li className="nav-item">
          <a href="https://nuortenideat.fi" target="blank">
            nuortenideat.fi
          </a>
        </li>
        <li className="nav-item">
          <a href="https://otakantaa.fi" target="blank">
            otakantaa.fi
          </a>
        </li>
        <li className="nav-item">
          <a href="https://lausuntopalvelu.fi" target="blank">
            lausuntopalvelu.fi
          </a>
        </li>

        <li className="nav-item">
          <a href="https://vaalit.fi" target="blank">
            vaalit.fi
          </a>
        </li>
        <li className="nav-item">
          <a href="https://puoluerekisteri.fi" target="blank">
            puoluerekisteri.fi
          </a>
        </li>
      </ul>,
    ];
  }

  const getUserItems = () => {
    if (user) {
      return [
        <UserMenu user={user} key="userMenu"/>,
      ];
    }
    return [
      <FormattedMessage key="login" id="login">
        {login => (
          <Link
            id="login-button"
            type="button"
            to={`/auth/login?lang=${language}`}
            aria-label={login}
            className="digi-button"
          >
            <span className="user-name">{login}</span>
          </Link>
        )}
      </FormattedMessage>,
    ];
  }

  const getNavItem = (id, url, linkClassName) => {
    const active = history && history.location.pathname === url;
    const navLink = (
      <a href="#">
        <FormattedMessage id={id + 'HeaderText'} />
      </a>
    );
    if (url) {
      // Can't use custom link component here because it will break the navigation
      // so LinkContainer must contain same logic
      return (
        <li className={`nav-item ${active ? 'active' : ''}`}>
          <LinkContainer
            to={url + '?lang=' + language}
            className={"nav-link"+(!!linkClassName ? ` ${linkClassName}` : '')}
          >
            {navLink}
          </LinkContainer>
        </li>
      );
    }
    return <li className={`nav-item ${active && 'active'}`}>{navLink}</li>;
  }

  const contrastToggle = () => {
    if (config.enableHighContrast) {
      return (
        <FormattedMessage key="contrastTitle" id="contrastTitle">
          {text => (
            <Button
              key="text"
              aria-label={text}
              className="contrast-button"
              onClick={() => toggleContrast()}
            >
              <Icon name="adjust" />
              <span className="contrast-title">{text}</span>
            </Button>
          )}
        </FormattedMessage>
      );
    }
    return <div />;
  }

  const userItems = getUserItems();

  return (
    <div>
      <div className="super-search-placeholder">
        <iframe
          id="super-search-iframe"
          src="https://haku.demokratia.fi/#/?lan=fi&amp;site=otakantaa.fi"
        />
      </div>
      <div className="nav-fixed-placeholder">
        {/*Takes the space when nav turns position:fixed*/}
      </div>
      <FormattedMessage id="headerPagesNavLabel">
        {headerPagesNavLabel => (
          <Navbar
            default
            collapseOnSelect
            className="navbar-primary"
            aria-label={headerPagesNavLabel}
          >
            <div className="background-container">
              <div className="navbar-primary__ball" />
            </div>
            <Navbar.Header>
              <Navbar.Brand>
                <LangLink to={{ path: '/' }}>
                  <img
                    src={
                      language === 'sv'
                        ? '/assets/images/digiraati-logo-sv.png'
                        : '/assets/images/digiraati-logo-fi.png'
                    }
                    height="50"
                    alt={getMessage('digiraatiHomepage')}
                  />
                </LangLink>
                
              </Navbar.Brand>
              <FormattedMessage id="mainMenu">
                {mainMenu => <Navbar.Toggle aria-label={mainMenu} />}
              </FormattedMessage>
            </Navbar.Header>
            <Navbar.Collapse>
              <Nav>
                {isAdmin(user) && (
                <MenuItem>
                  {getNavItem('createHearing', '/hearing/new', 'digi-button new-hearing-button')}
                </MenuItem>
                )}
                <MenuItem>{getNavItem('info', '/info')}</MenuItem>
                <MenuItem>{getNavItem('participate', '/participate')}</MenuItem>
                <MenuItem>
                  {getNavItem('hearingJury', '/hearings/list')}
                </MenuItem>
                {userItems}
                <MenuItem>
                  <LanguageSwitcher currentLanguage={language} />
                </MenuItem>

              </Nav>
            </Navbar.Collapse>
          </Navbar>
        )}
      </FormattedMessage>
    </div>
  );
}

Header.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  dispatch: PropTypes.func,
  history: PropTypes.object,
  language: PropTypes.string,
  user: PropTypes.object,
  toggleContrast: PropTypes.func,
};

Header.contextTypes = {
  history: PropTypes.object,
};

const mapDispatchToProps = dispatch => ({
  toggleContrast: () => dispatch(toggleContrast()),
});

export default withRouter(
  connect(
    state => ({
      user: getUser(state), // User dropdown requires this state
      language: state.language, // Language switch requires this state
      router: state.router, // Navigation activity requires this state
    }),
    mapDispatchToProps,
  )(Header),
);
