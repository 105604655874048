import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { getUser } from "../../store/selectors/user";
import { Helmet } from "react-helmet";
import getMessage from "../../utils/getMessage";
import { Field, Formik } from "formik";
import {  object } from 'yup';
import config from "../../config";
import { getCurrentUser } from "../../store/actions/user";
import DigiraatiPrivacyCheckbox, { DigiraatiPrivacyCheckboxValidationSchema } from "./Fields/PrivacyCheckbox";
import DigiraatiUsernameInput, { DigiraatiUsernameInputValidationSchema } from "./Fields/UsernameInput";
import { StyledForm } from "./Registration";
import { responseBodyToResponseErrorLocalizationKeys } from "../../utils/validation";
import { localizedNotifyError } from "../../utils/notify";


const MissingInformation = ({user, getCurrentUser}) => {

  const updateMissingInformation = async (info) => {
    // the fields appear conditionally and do partial updates so whitelist
    // only fields with values
    const whitelistedData = {
      ...(info.hasOwnProperty('is_approved_privacy') ? {'is_approved_privacy': info.is_approved_privacy} : {}),
      ...(info.hasOwnProperty('username') ? {'username': info.username} : {}),
    }
    const token = localStorage.getItem("token");
    if (!token) return null;
    const result = await fetch(`${config.apiBaseUrl}/v1/users/current/`, {
      method: "PATCH",
      headers: {
        Authorization: "Token " + token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(whitelistedData),
    });
    return result;
  }

  const onSubmit = async (e, values, isValid, setTouched, setFieldTouched, setSubmitting, setErrors) => {
    e.preventDefault();
    if (!isValid) {
      Object.keys(values).forEach( (key) => {
        setFieldTouched(key, true);
      } )
      return;
    }
    setSubmitting(true)
    const response = await updateMissingInformation(values);
    if (!response.ok) {
      const responseBody = await response.json();
      try {
        const errors = responseBodyToResponseErrorLocalizationKeys(responseBody);
        setErrors(errors);
      } catch (e) {}
      localizedNotifyError("userInfoUpdateFailed");
      setSubmitting(false);
    } else {
      getCurrentUser();
    }
  }

  const ALL_FIELDS = {
    is_approved_privacy: {
      initialValue: false,
      validationSchema: DigiraatiPrivacyCheckboxValidationSchema,
    }, 
    username: {
      initialValue: '',
      validationSchema: DigiraatiUsernameInputValidationSchema,
    }, 
  }

  // Only add values and schemas for keys that are in user.missing_information
  const initialValues = user?.missing_information.reduce( 
    (prev, cur) => ({...prev, [cur]: ALL_FIELDS[cur].initialValue}),
    {}
  );
  const validationSchemas = user?.missing_information.reduce(
    (prev, cur) => ({...prev, ...(ALL_FIELDS[cur].validationSchema)}),
    {}
  );

  const formValidationSchema = object().shape(validationSchemas);

  return (
    <Formik
        initialValues={initialValues}
        validationSchema={formValidationSchema}
        validateOnMount={true}
      >
        {({
          values,
          setSubmitting,
          isSubmitting,
          isValid,
          setTouched,
          setFieldTouched,
          setErrors,
        }) => (
          
      <StyledForm className="hds-form hds-form--login" onSubmit={(e) => onSubmit(e, values, isValid, setTouched, setFieldTouched, setSubmitting, setErrors)}>
        <Helmet title={getMessage('missingInformationTitle')} />
        <h3>{getMessage('missingInformationTitle')}</h3>
        <div className="hds-card">
          <div className="hds-fieldset">
          <p>{getMessage('missingInformationDescription')}</p>

          {
            user?.missing_information.includes('username') && (
              <div>
                <h4>{getMessage('missingUsernameDescription')}</h4>
                <Field
                  name="username"
                  component={DigiraatiUsernameInput}
                />
              </div>
            )
          }
          
          {
            user?.missing_information.includes('is_approved_privacy') && (
              <div>
                <h4>{getMessage('missingTosApprovedDescription')}</h4>
                <Field
                  name="tos_approved"
                  required
                  component={DigiraatiPrivacyCheckbox}
                />
              </div>
            )
          }

          <button type="submit" className="digi-button" disabled={!isValid || isSubmitting}>
            {getMessage('submit')}
          </button>
          </div>
        </div>
      </StyledForm>
        )}
    </Formik>
  )
}

const mapDispatchToProps = dispatch => ({
  getCurrentUser: () => {
    dispatch(getCurrentUser());
  },
});

export default withRouter(
  connect(
    state => ({
      user: getUser(state),
      language: state.language, // Language switch requires this state
    }),
    mapDispatchToProps,
  )(MissingInformation),
);
